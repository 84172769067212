/* App.css */
.container {
  width: 100%;
  max-width: 1200px; /* or 1440px or whatever maximum you prefer */
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box; /* This makes sure padding doesn't add to the total width */
}

/* Optional: You might want to add responsive breakpoints */
@media (max-width: 768px) {
  .container {
      max-width: 100%;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(245, 245, 245, 0.95);
  padding: 0;  /* No side padding at this level */
  height: 60px;
  box-sizing: border-box;
}

/* Styling for the inner container of the header */
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;  /* Take full height of the header */
  padding: 0 2rem;  /* Side padding for inner content */
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;  /* This centers the container */
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
}

header .logo {
  font-size: 1.5em;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center; /* aligns the nav items vertically */
  padding-left: 0; /* removes any default padding on the list */
  gap: 1rem; /* creates space between inline items, adjust as needed */
}

nav ul li {
  display: inline;
  margin-right: 1rem;
}

.auth-page button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
}


.spacer {
  height: 60px; /* this should be the same height as your header */
}

.error {
  background-color: #e74c3c;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}
