.tagline {
    font-size: 1.5rem;
    margin-bottom: 2rem; /* Adjust as needed */
}

/* Additional styles for the button for better appearance */
.btn-primary {
    padding: 0.6rem 1rem;
    background-color: #FF6200; /* Or your desired color */
    border: none;
}

.btn-primary:hover {
    background-color: #FF7A19; /* A slightly lighter shade for the hover effect */
}

.hero-section {
    padding-top: 10px; /* This is crucial for ensuring space below the fixed header */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    position: relative;
    height: 70vh;
    color: black; /* or any other color you prefer */
}


.hero-title {
    align-self: center; /* Center the title block horizontally */
    /* No need for margin-top as justify-content: flex-start takes care of positioning */
}

.cta-container {
    position: absolute;
    bottom: 2rem; 
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}
