/* General spacing */
.container {
    margin-top: 50px;
    margin-bottom: 50px;
}

/* Separation and background */
.row > .col-md-6:first-child {
    background-color: #f7f8fa; /* Lighter color */
    padding: 20px 40px;
    border-right: 1px solid #e0e0e0; /* Vertical line */
}

.row > .col-md-6:last-child {
    padding: 20px 40px;
}

/* Modern form styling */
input[type="text"], input[type="password"], input[type="email"], .auth-page button {
    width: 100%; /* Full width */
    padding: 10px 15px;
    margin-bottom: 15px; /* Space between fields and button */
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s, box-shadow 0.2s;

}

input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus {
    border-color: #007bff; /* Highlight color when focused */
    outline: none; /* Remove default browser outline */
}

button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border: none;
    transition: background-color 0.2s; /* Transition for a smoother effect */
}

button:hover {
    background-color: #0056b3; /* Darken on hover */
    transition: background-color 0.2s, box-shadow 0.2s;
}

.auth-form button{
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border: none;
 }
 

 .auth-form:hover {
    background-color: #0056b3; /* Darken on hover */
 }
 
 .auth-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form-container h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.auth-form-container input[type="text"], 
.auth-form-container input[type="email"], 
.auth-form-container input[type="password"] {
    margin-bottom: 15px;
}

.auth-form-container input[type="submit"] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    width: auto; /* Not fully expanded */
    transition: background-color 0.2s;
}

.auth-form-container input[type="submit"]:hover {
    background-color: #0056b3;
}

.separator {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0; 
    margin: 20px 0; /* Vertical space before and after */
}

/* Centering the button on the left panel */
.row > .col-md-6:first-child h2,
.row > .col-md-6:first-child p {
    margin-bottom: 20px;
}

/* Social buttons styling */
.social-login {
    margin-top: 10px;
}

.social-login .btn {
    margin-bottom: 10px; /* Space between social buttons */
    padding: 10px 15px;
    color: #fff; /* Text color */
}

.social-login .btn-secondary {
    background-color: #757575; /* General grayish color */
}

/* Google and Facebook specific styles */
.social-login .btn-google {
    background-color: #DB4437;
}

.social-login .btn-facebook {
    background-color: #3B5998;
}

