.testimonials {
    padding: 3rem 0;
    background-color: #f9f9f9; /* This subtle color difference can help visually separate the section */
    text-align: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow around the section */
    border-radius: 10px; /* Optional: if the section's edges need to be slightly rounded */
    margin: 2rem 0; /* Adds space around the section, making the shadow more visible */
}

.section-title {
    color: #333;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    text-transform: uppercase; /* Optional: makes the title text all uppercase for style */
}

.testimonial-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.testimonial-image-wrapper {
    position: relative;
    width: 150px; /* Adjust as needed */
    height: 150px;
}

.testimonial-image {
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Creates the circle shape */
    object-fit: cover;
}

.testimonial-amount {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: #4CAF50; /* A nice shade of green */
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
}

.testimonial-info {
    max-width: 500px;
}

.testimonial-stars {
    display: block;
    margin: 0.5rem auto;
    width: 100px; /* Adjust as needed */
}

.testimonial-message {
    color: #666;
    font-style: italic;
    margin-top: 1rem;
}

/* Responsiveness */
@media (max-width: 768px) {
    .testimonial-content {
        flex-direction: column;
    }

    .testimonial-info {
        margin-top: 2rem;
    }
}
