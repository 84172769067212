.dashboard {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
  }
  
  .dashboard h2 {
    color: #333;
    margin-bottom: 16px;
  }
  
  .dashboard p {
    color: #555;
  }
  