.faq-section {
    background-color: #f9f9f9; /* A light background color to separate the FAQ section from other sections */
}

.faq-section h2 {
    color: #333; /* Dark color for the heading for better readability */
}

.accordion-button {
    font-weight: 500; /* Slightly bolder font for the questions */
    color: #333; /* Dark color for better readability */
    text-align: left; /* Align the text to the left for a cleaner look */
    padding: 1rem; /* Comfortable padding around the button */
    outline: none; /* Remove the default outline to avoid a boxy appearance */
    transition: background-color 0.3s; /* Smooth transition for hover effects */
}

.accordion-button:not(.collapsed) {
    color: #0d6efd; /* Highlight color when the accordion is open */
    background-color: #e9ecef; /* Light background color for an open accordion item */
}

.accordion-body {
    text-align: left; /* Align the text to the left for a cleaner look */
    color: #555; /* Darker text color for the answers for readability */
}
