.how-it-works {
    padding: 2rem 0;
    background-color: #f9f9f9; /* You can choose a color that fits your design aesthetic */
    text-align: center;
}

.section-title {
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #333; /* Or another color that suits your design */
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem; /* Adjust based on your preference and the available space */
}

.step {
    flex-basis: 20%; /* this means it takes up to 20% of the space, adjust based on your preference */
    min-width: 300px; /* Adjust based on your preference */
    background-color: #fff; /* Or any other color */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: for a bit of depth */
    padding: 2rem 1rem;
    border-radius: 10px; /* Optional: if you prefer rounded corners */
}

.step-image {
    max-width: 100%; /* Ensures the image doesn’t exceed the container width */
    height: auto; /* Ensures the height scales proportionally */
    margin-bottom: 1rem;
}

.step h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333; /* Or another color that suits your design */
}

.step p {
    color: #666; /* Or another color that suits your design */
    line-height: 1.6;
}

/* Consider adding responsive adjustments if necessary */

@media (max-width: 960px) {
    .steps-container {
        flex-direction: column;
    }

    .step {
        flex-basis: auto;
        width: 70%; /* or 100% if you want it to go full width on small screens */
        margin-left: auto;
        margin-right: auto; /* centers it */
    }
}
